@import url("https://fonts.googleapis.com/css2?family=Jersey+25&display=swap");
* {
  box-sizing: border-box;
}
body {
  background-color: #1e424c;
  color: white;
  font-family: "Jersey 25", ubuntu, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.App {
  width: 1200px;
  min-height: 720px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* gap: 1.5rem; */
}
.App button {
  width: 120px;
  height: 30px;
  background-color: #8d230f;
  color: #c99e10;
  border: none;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.App button:hover {
  transform: scale(1.1);
}

.game-board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  margin: 0 auto;
  padding: 10px 30px;
}
